import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import LoadingDialog from './LoadingDialog';
import BACKEND_URL from './config'; // Import the BACKEND_URL from the config file

const BookShipmentDialog = ({ isOpen, onClose, quote, showAlert, removeRowsWithSameSalesOrder }) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    if (!isOpen) {
        return null;
    }

    const handleBookShipment = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${BACKEND_URL}/api/book-shipment`, { quote }, { withCredentials: true });
            if (response.status === 200 && response.data.success) {
                showAlert('Success', 'Shipment booked successfully!');
                removeRowsWithSameSalesOrder(quote[0]);
                onClose();
            } else {
                // Show the error message from the server if available, otherwise show a generic message
                const errorMessage = response.data && response.data.error 
                    ? response.data.error 
                    : 'Failed to book shipment. Please try again.';
                console.log(response.data.success)
                console.log(response.status)
                showAlert('Error', errorMessage);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else if (error.response && error.response.data && error.response.data.error) {
                showAlert('Error', `Failed to book shipment: ${error.response.data.error}`);
            } else {
                console.error('Error booking shipment:', error);
                showAlert('Error', `An unexpected error occurred: ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <LoadingDialog />}
            <div className="dialog-overlay">
                <div className="dialog">
                    <h2>Booking Confirmation</h2>
                    <p>Sales Order: {quote[0]}</p>
                    <p>Carrier: {quote[1]}</p>
                    <p>Quote ID: {quote[2]}</p>
                    <p>Total Charges: {quote[3]}</p>

                    <div className="dialog-buttons">
                        <button onClick={handleBookShipment}>Book Shipment</button>
                        <button onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookShipmentDialog;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './tableStyles.css';
import BookShipmentDialog from './BookShipmentDialog';
import { useNavigate } from 'react-router-dom';
import CustomAlert from './CustomAlert';
import BACKEND_URL from './config'; // Import the BACKEND_URL from the config file

const QuotesTable = () => {
    const [data, setData] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [error, setError] = useState('');
    const [alert, setAlert] = useState({ show: false, title: '', message: '' });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/api/quotes`, {
                    withCredentials: true, // Ensure that the session cookie is sent
                });
                setColumnNames(response.data.column_names);
                setData(response.data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                    console.error('Error fetching data:', error);
                } else {
                    setError('Error fetching data');
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [navigate]);

    const handleBookShipment = (quote) => {
        setSelectedQuote(quote);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleShowAlert = (title, message) => {
        setAlert({ show: true, title, message });
    };

    const handleCloseAlert = () => {
        setAlert({ show: false, title: '', message: '' });
    };

    const removeRowsWithSameSalesOrder = (salesOrder) => {
        setData((prevData) => prevData.filter(row => row[0] !== salesOrder));
    };

    const getRowClass = (index, salesOrder) => {
        if (index === 0) return 'row-odd';
        const prevSalesOrder = data[index - 1][0];
        return prevSalesOrder === salesOrder ? data[index - 1].className : (data[index - 1].className === 'row-odd' ? 'row-even' : 'row-odd');
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="table-container">
            <h2>Quotes Table</h2>
            <table>
                <thead>
                    <tr>
                        {columnNames.map((col, index) => (
                            col !== "Internal ID" && <th key={index}>{col}</th>
                        ))}
                        <th>Book Shipment</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => {
                        const rowClass = getRowClass(rowIndex, row[0]);
                        row.className = rowClass;
                        return (
                            <tr key={rowIndex} className={rowClass}>
                                {row.map((cell, cellIndex) => (
                                    columnNames[cellIndex] !== "Internal ID" && <td key={cellIndex}>{cell}</td>
                                ))}
                                <td>
                                    <button onClick={() => handleBookShipment(row)}>Book Shipment</button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {selectedQuote && (
                <BookShipmentDialog
                    isOpen={isDialogOpen}
                    onClose={handleCloseDialog}
                    quote={selectedQuote}
                    showAlert={handleShowAlert} // Now it passes parameters
                    removeRowsWithSameSalesOrder={removeRowsWithSameSalesOrder}
                />
            )}
            <CustomAlert
                show={alert.show}
                handleClose={handleCloseAlert}
                title={alert.title}
                message={alert.message}
            />
        </div>
    );
};

export default QuotesTable;

import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import './styles.css';
import { AuthContext } from './AuthContext';
import logo from './assets/logo.png';  // Ensure the correct path to the logo

const Sidebar = () => {
    const { setLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        await fetch('/api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        localStorage.removeItem('token');
        setLoggedIn(false);
        navigate('/login');
    };

    return (
        <div className="sidebar">
            <div className="freight-ops-tms">
                <img src={logo} alt="Freight Ops TMS Logo" className="sidebar-logo" />
            </div>
            <Nav className="flex-column">
                <Nav.Item>
                    <Nav.Link as={Link} to="/home">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/quotes">Quotes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/spot-quotes">Spot Quotes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
};

export default Sidebar;

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ element: Component }) => {
    const { loggedIn, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>; // or a loading spinner
    }

    return loggedIn ? <Component /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
